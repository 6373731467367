<template>
  <div class="twitter">
    Ordinals
  </div>
</template>

<script>
export default {
  name: 'Twitter',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
